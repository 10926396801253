<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-09-17 16:35:01
 * @LastEditTime: 2021-09-18 15:10:04
 * @Description: 生产发货情况
 * @Param:
 * @FilePath: \JT_Web_test\src\views\enterpriseScreen_JN\productionDelivery.vue
 -->

<template>
    <div class="productionDelivery">
        <ul class="taskStatistics">
            <li>
                <h4>总任务(个)：</h4>
                <p class="scrollNumbers scrollNumbers1" id="totalTask"></p>
            </li>
            <li>
                <h4>任务方量(方)：</h4>
                <p class="scrollNumbers scrollNumbers2" id="taskPartyQuantity"></p>
            </li>
            <li>
                <h4>发货方量(方)：</h4>
                <p class="scrollNumbers scrollNumbers2" id="shipperQuantity"></p>
            </li>
            <li>
                <h4>完成方量(方)：</h4>
                <p class="scrollNumbers scrollNumbers2" id="complateQuantity"></p>
            </li>
            <li>
                <h4>完成率(%)：</h4>
                <p class="scrollNumbers scrollNumbers2" id="completionRate"></p>
            </li>
        </ul>
        <h4 class="title">
            发货情况
        </h4>
        <div class="chartsStyle" id="deliveryStatusChart"></div>
        <h4 class="title">
            发货时间
        </h4>
        <div class="chartsStyle" id="deliveryTimeChart"></div>
        <h4 class="title">
            生产物流车次
        </h4>
        <div class="logisticsTrainNumber">
            <div class="top-block">
                <div class="send-car-statis">
                    <span>已发车(车)：</span><div class="scrollNumbers scrollNumbers3" id="departure"></div>
                </div>
                <el-input placeholder="请输入内容" v-model="searchVal" class="inputStyle">
                    <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
                </el-input>
            </div>
            <el-table
                border
                height="100%"
                :data="productionDeliveryTable"
                class="tableStyle"
            >
                <el-table-column
                    prop="shch"
                    label="车小号"
                    width="50"
                >
                </el-table-column>
                <el-table-column
                    prop="pump_plate_number"
                    label="车牌号"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    prop="project_name"
                    label="工程名称"
                >
                    <template slot-scope="scope">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="scope.row.project_name"
                            placement="top"
                        >
                            <span>{{ scope.row.project_name.substr(0,11) }}</span>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="biaoh"
                    label="型号"
                    width="78"
                >
                </el-table-column>
                <el-table-column
                    prop="grand_total_complete_quantity"
                    label="运输量"
                    width="70"
                >
                </el-table-column>
                <el-table-column
                    prop="task_number"
                    label="单号"
                    width="80"
                >
                    <template slot-scope="scope">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="scope.row.task_number"
                            placement="top"
                        >
                            <span>{{ scope.row.task_number.substr(10) }}</span>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="pcsj"
                    label="派车时间"
                    width="80"
                >
                    <template slot-scope="scope">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="scope.row.pcsj"
                            placement="top"
                        >
                            <span>{{ scope.row.pcsj.substr(11) }}</span>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column
                    label="状态"
                    width="70"
                >
                    <template slot-scope="scope">
                        {{ scope.row.status | state }}
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-block">
                <span class="total">共{{ total }}条</span>
                <el-pagination
                    small
                    background
                    layout="sizes,prev, pager, next, jumper"
                    :current-page="tablePage"
                    :total="total"
                    :page-sizes="[20, 50, 100, 300]"
                    :page-size="pageSize"
                    :pager-count="5"
                    @size-change="pageSizeChange"
                    @current-change="pageCurrentChange"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import largeScreenFun from './JS/index.js';
import NP from 'number-precision';
NP.enableBoundaryChecking(false);
import {
    drawBar,
} from './../../assets/js/charts';

export default {
    name: 'production-delivery',
    components: {},
    props: {},
    data() {
        return {
            time: '',
            stationId: '',

            rcount: 0,
            quantity: 0,
            volume: 0,
            frcount: 0,
            fillvolume: 0,

            searchVal: '',
            productionDeliveryTable: [],

            // 列表分页下标
            tablePage: 0,
            // 列表总条数
            pageSize: 20,
            total: 0,
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {},
    methods: {
        async initPage() {
            this.time = this.$parent.time;
            this.stationId = this.$parent.stationId;

            this.searchVal = '';
            this.tablePage = 0;
            this.loadPage();
        },
        async loadPage() {
            const shipmentStatistics = await largeScreenFun.getShipmentStatistics(this.time, this.stationId);
            if (shipmentStatistics) {
                this.loadShipmentStatistics(shipmentStatistics);
            }

            const shipmentStatisticsTime = await largeScreenFun.getShipmentStatistics_time(this.time, this.stationId);
            this.loadScatterPlot(shipmentStatisticsTime);
            this.getPageData();

            try {
                const deliveryStatus = await largeScreenFun.getDeliveryStatus(this.time, this.stationId);
                if (deliveryStatus) {
                    this.loadDrawBar(deliveryStatus);
                }
                // eslint-disable-next-line no-empty
            } catch (e) {}
        },
        // 获取分页数据
        async getPageData() {
            this.loading = true;
            const res = await largeScreenFun.getProductionDeliveryTable(this.time, this.stationId, this.searchVal, this.tablePage, this.pageSize);
            this.total = Number(res.count);
            this.productionDeliveryTable = res.List;
            this.loading = false;
        },
        search() {
            this.tablePage = 0;
            this.getPageData();
        },
        // 更改页码大小
        pageSizeChange(size) {
            this.pageSize = size;
            this.getPageData();
        },
        // 更改页数
        pageCurrentChange(page) {
            this.tablePage = page;
            this.getPageData();
        },
        // 加载发货统计数据
        loadShipmentStatistics(data) {
            if (this.rcount !== data.rcount) {
                this.rcount = data.rcount;
                largeScreenFun.electronicNumber('#totalTask', this.rcount || 0);
            }
            if (this.quantity !== data.quantity) {
                this.quantity = data.quantity;
                largeScreenFun.electronicNumber('#taskPartyQuantity', this.quantity || 0);
                let completionRate = 0;
                if (Number(data.quantity) > 0) {
                    completionRate = NP.times(NP.divide(data.fillvolume || 0, data.quantity), 100).toFixed(2);
                } else {
                    completionRate = data.fillvolume * 100;
                }
                largeScreenFun.electronicNumber('#completionRate', completionRate);
            }
            if (this.volume !== data.volume) {
                this.volume = data.volume;
                largeScreenFun.electronicNumber('#shipperQuantity', this.volume || 0);
            }
            if (this.fillvolume !== data.fillvolume) {
                this.fillvolume = data.fillvolume;
                largeScreenFun.electronicNumber('#complateQuantity', this.fillvolume || 0);
            }
            if (this.frcount !== data.frcount) {
                this.frcount = data.frcount;
                largeScreenFun.electronicNumber('#departure', this.frcount || 0);
            }
        },

        // 加载柱图-发货情况
        loadDrawBar(row) {
            const chartData = {
                xAxis: {
                    data: [],
                },
                yAxis: {
                    name: '(方)',
                    nameTextStyle: [0, 0, -7, 0],
                },
                series: [
                    {
                        name: '销售方量',
                        barWidth: 24,
                        color: [ '#20E5FF', '#2F7EF2'],
                        data: [],
                    }, {
                        name: '发货方量',
                        barWidth: 24,
                        color: ['#FFC452', '#FB7D3C' ],
                        data: [],
                    },
                ],
            };
            row.forEach(item => {
                chartData.xAxis.data.push(item.name);
                chartData.series[0].data.push(item.xsfl);
                chartData.series[1].data.push(item.fhfl);
            });
            drawBar('deliveryStatusChart', chartData);
        },

        // 加载散点图-发货时间
        loadScatterPlot(row) {
            const chartData = {
                xAxis: {
                    data: [],
                },
                yAxis: {
                    data: [],
                },
                series: {
                    data: [],
                },
            };
            if (row?.length > 0) {
                const new_xAxis = [...new Set(row.map(item => `${item.warning_time} : 00`))];
                const new_yAxis = [...new Set(row.map(item => item.biaoh))];
                const new_series = [];
                const result = row.reduce((a, b) => {
                    if (a[`${b.warning_time} : 00`]) {
                        a[`${b.warning_time} : 00`].push(b);
                    } else {
                        a[`${b.warning_time} : 00`] = [b];
                    }
                    return a;
                }, {});
                new_xAxis.forEach((item, index) => {
                    const row = result[item];
                    row.forEach(k => {
                        const newIndex = new_yAxis.findIndex(item => item === k.biaoh);
                        new_series.push([index, newIndex, k.volume]);
                    });
                });
                chartData.xAxis.data = new_xAxis;
                chartData.yAxis.data = new_yAxis;
                chartData.series.data = new_series;
            }
            this.scatterPlot('deliveryTimeChart', chartData);
        },

        // 散点图
        scatterPlot(obj, chartData) {
            const chart = this.$echarts.init(document.getElementById(obj));
            const xAxisData = chartData.xAxis.data;
            const yAxisData = chartData.yAxis.data;
            let data = chartData.series.data;
            data = data.map(function (item) {
                return [item[1], item[0], item[2]];
            });
            const option = {
                tooltip: {
                    position: 'top',
                    formatter: function (params) {
                        return `${yAxisData[params.value[1]]} ${xAxisData[params.value[0]]} ${params.value[2]}`;
                    },
                },
                grid: {
                    top: 20,
                    left: 60,
                    bottom: 20,
                    right: 20,
                },
                xAxis: {
                    type: 'category',
                    data: xAxisData,
                    boundaryGap: false,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(255,255,255,0.1)',
                            type: 'dashed',
                        },
                    },
                    axisLabel: {
                        interval: 1,
                        color: 'rgba(255,255,255,0.5)',
                    },
                    axisLine: {
                        show: false,
                    },
                },
                yAxis: {
                    type: 'category',
                    data: yAxisData,
                    axisLabel: {
                        interval: 0,
                        color: 'rgba(255,255,255,0.5)',
                        margin: 20,
                    },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                },
                series: [{
                    type: 'scatter',
                    symbolSize: function (val) {
                        return val[2] * 2;
                    },
                    data: data,
                    animationDelay: function (idx) {
                        return idx * 5;
                    },
                }],
            };
            if (yAxisData.length > 7) {
                option.dataZoom = [// 给x轴设置滚动条
                    {
                        show: true,
                        start: 0, // 默认为0
                        end: 7 / yAxisData.length * 100, // 默认为100
                        type: 'slider',
                        maxValueSpan: 10, // 窗口的大小，显示数据的条数的
                        yAxisIndex: [0],
                        handleSize: 0, // 滑动条的 左右2个滑动条的大小
                        // width: 60,
                        height: '80%', // 组件高度
                        left: 690,
                        right: 8,
                        bottom: 20,
                        borderColor: 'rgb(0 0 0 / 10%)',
                        fillerColor: '#4C658A',
                        backgroundColor: '#01132c', // 两边未选中的滑动条区域的颜色
                        showDataShadow: false, // 是否显示数据阴影 默认auto
                        showDetail: false, // 即拖拽时候是否显示详细数值信息 默认true
                        realtime: true, // 是否实时更新
                        filterMode: 'filter',
                    },
                    // 下面这个属性是里面拖到
                    {
                        show: true,
                        type: 'inside',
                        yAxisIndex: [0],
                        start: 0, // 默认为1
                        end: 7 / yAxisData.length * 100, // 默认为100
                    },
                ];
            }
            chart.setOption(option);
        },
    },
    filters: {
        state(val) {
            let str = '';
            switch (val) {
                case '1' :
                    str = '已入场';
                    break;
                case '2' :
                    str = '已入场称重';
                    break;
                case '21' :
                    str = '已派单';
                    break;
                case '3' :
                    str = '已接单';
                    break;
                case '31' :
                    str = '已扫码';
                    break;
                case '4' :
                    str = '已装料';
                    break;
                case '5' :
                    str = '已出场称重';
                    break;
                case '6' :
                    str = '已出场';
                    break;
                case '7' :
                    str = '已到达';
                    break;
                case '73' :
                    str = '卸料中';
                    break;
                case '77' :
                    str = '已卸料';
                    break;
                case '8' :
                    str = '已交付';
                    break;
                case '9' :
                    str = '车辆维修';
                    break;
                case '10' :
                    str = '车辆停驶';
                    break;
            }
            return str;
        },
    },
};
</script>

<style scoped lang="stylus"></style>
